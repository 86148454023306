import React from 'react';
import { useMediaQuery } from 'react-responsive';

const logo3 = 'https://media.graphcms.com/BvbAy3HpRfeayn0RnlpD';

const Footer = () => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    return(
        <footer style={{backgroundColor:"black", color:"white", display:"flex", justifyContent:"space-around"}}>
            {isDesktopOrLaptop && <img styles={{width:"200px", height:"300px"}} src={logo3} alt="Logo" />}
            {isTabletOrMobile && <img styles={{width:"100px", height:"150px"}} src={logo3} alt="Logo" />}            
            <ul style={{listStyle:"none", marginTop:"auto" }}>
                <h3>Przydatne linki</h3>    
                <li><a href="https://diecezja.waw.pl/">Diecezja Warszawsko-Praska</a></li>
                <li><a href="https://wsddwp.edu.pl/">Wyższe Seminarium Duchowne diecezji Warszawsko-Praskiej</a></li>
                <li><a href="http://idziemy.pl/">Idziemy</a></li>
                <li><a href="https://www.gosc.pl/">Gość niedzielny</a></li>
                <li><a href="https://www.malygosc.pl/">Mały gość</a></li>
            </ul>
        </footer>
    );
}

export default Footer;