import React from 'react';
import { Container, Row } from "react-bootstrap";
import { Redirect, Route, Switch } from "react-router-dom";
import RouteChangeTracker from './RouteChangeTracker';

import Galleries from '../../../Pages/Gallery/Galleries';
import Home from '../../../Pages/Home/Home';
import Contact from '../../../Pages/Contact/Contact';
import Parafia from '../../../Pages/Parafia/Parafia';
import ParafiaItemsList from '../../../Pages/Parafia/ParafiaItemsList';
import News from '../../../Pages/News/News';
import NewsList from '../../../Pages/News/NewsList';
import Online from '../../../Pages/OnlineVideos/Online';
import Intentions from '../../../Pages/Intentions/Intentions';
import Intention from '../../../Pages/Intentions/Intention';
import Actuals from '../../../Pages/Actuals/Actuals';
import Actual from '../../../Pages/Actuals/Actual';
import Sacraments from '../../../Pages/Sacraments/Sacraments';

const PageContent = () => {

    return (
        <Container fluid={true} as={"main"}>
            <RouteChangeTracker />
            <Row>
                <Switch>
                    <Route path={"/"} exact render={() => <Home />} />
                    <Route path={"/"} exact render={() => null} />
                    <Route path={"/aktualnosci"} exact render={() => <Actuals />} />
                    <Route path={"/aktualnosc/:slug"} exact render={(props) => <Actual {...props} />} />
                    <Route path={"/galerie"} exact render={() => <Galleries />} />
                    <Route path={"/kontakt"} exact render={() => <Contact />} />
                    <Route path={"/parafia"} exact render={() => <ParafiaItemsList />} />
                    <Route path={"/oParafii/:slug"} exact render={(props) => <Parafia {...props} />} />
                    <Route path={"/ogloszenia"} exact render={() => <NewsList />} />
                    <Route path={"/ogloszenia-od/:slug"} exact render={(props) => <News {...props} />} />
                    <Route path={"/live"} exact render={() => <Online />} />
                    <Route path={"/intencje"} exact render={() => <Intentions />} />
                    <Route path={"/intencje-od/:slug"} exact render={(props) => <Intention {...props} />} />
                    <Route path={"/sakramenty"} exact render={() => <Sacraments />} />
                    <Redirect to={"/"} />
                </Switch>
            </Row>
        </Container>
    );
}


export default PageContent;