import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import ImageGallery from 'react-image-gallery';
import ReactHtmlParser from 'react-html-parser';
import "react-image-gallery/styles/css/image-gallery.css";
import ReactGA from "react-ga";

const NewsBySlug = `
    query($slug: String!){
        ogloszenias(where:{slug:$slug}){
            title
            slug
            content{
              html
            }
            createdBy {
              name
            }
            dodano
            photos {
              url(
                transformation:{
                  image:{ resize: {width:600, height:1000} }
                }
              )
            }
        }
    }
`;

const News = (props) => {
  ReactGA.send({ hitType: "pageview", page: "/ogloszenia-od", title: "Ogłoszenia Od" });

  const param = useParams()
  const slug = param.slug

  const [result] = useQuery({
    query: NewsBySlug,
    variables: {
      slug
    }
  })

  const { data, fetching, err } = result;

  const [photosUrls, setPhotosUrls] = useState([]);

  useEffect(() => {
    if (data?.ogloszenias[0].photos) {
      const array = data?.ogloszenias[0].photos.map((obj, index) => {
        return {
          id: index,
          original: obj.url,
          thumbnail: obj.url
        }
      })
      setPhotosUrls(array)
    }
  }, [data])

  if (fetching) return <p>Pobieram informacje z wpisu...</p>
  if (err) return <p>O nie... {err.message}</p>

  return (
    <>
      <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px" }}>
        <div key={slug} style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
          {data.ogloszenias?.map((props) => (
            <>
              <div style={{ marginLeft: "30px", marginRight: "30px" }}>
                {ReactHtmlParser(props.content.html)}
              </div>
              {photosUrls.length > 0 &&
                <ImageGallery
                  items={photosUrls}
                  slideOnThumbnailOver={true}
                />
              }
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default News;