import React, { useState, useEffect } from 'react';
import { useQuery, gql } from 'urql';
import ReactHtmlParser from "react-html-parser";
import { ListBox } from 'primereact/listbox';
import { Dropdown } from "primereact/dropdown";
import ImageGallery from 'react-image-gallery';
import { useMediaQuery } from 'react-responsive';
import ReactGA from "react-ga";

const AllAbout = gql`
 {
    oParafiis(orderBy: dodano_DESC){
      title
      slug
      content{
        html
      }
      pictures {
        url
      }
    }
  }
 `;

const ParafiaItemsList = () => {
  ReactGA.send({ hitType: "pageview", page: "/parafia", title: "Parafia" });

  const [result] = useQuery({ query: AllAbout })
  const { data, fetching, err } = result;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedAboutsObj, setSelectedAboutsObj] = useState();
  const [items, setItems] = useState([]);
  const [photosUrls, setPhotosUrls] = useState([]);

  const getAboutsObj = (id) => {
    setSelectedAboutsObj(data?.oParafiis[id])
  }

  useEffect(() => {
    const getAbouts = async () => {
      setItems(data?.oParafiis?.map((obj, i) => ({
        label: obj.title.length > 30 ? obj.title.substring(0, 30) + ` ...` : obj.title,
        value: i
      })))
    }

    setSelectedAboutsObj(data?.oParafiis[selectedValue])
    getAbouts();

    if (data?.oParafiis[selectedValue].pictures) {
      const array = data?.oParafiis[selectedValue].pictures.map((obj, index) => {
        return {
          id: index,
          original: obj.url
        }
      })
      setPhotosUrls(array)
    }
  }, [data, selectedValue]);

  if (fetching) return <p>Pobieram informacje o parafii...</p>
  if (err) return <p>O nie... {err.Message}</p>

  return (
    <>
      {isDesktopOrLaptop &&
        <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px", }}>
          <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>

            <div style={{ width: "100%", marginLeft: "30px", marginRight: "30px", flexDirection: "column" }}>
              <h1 style={{ marginBottom: "10px" }}>{selectedAboutsObj?.title}</h1>
              {ReactHtmlParser(selectedAboutsObj?.content.html)}
            </div>
            <div style={{ width: "50%" }}>
              <ListBox
                filter
                value={selectedValue}
                options={items}
                onChange={(e) => {
                  setSelectedValue(e.value)
                  getAboutsObj(e.value)
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", paddingBottom: "11px" }}>
            {selectedAboutsObj?.pictures?.length === 1 && <img style={{ width: "500px", height: "600px", marginBottom: "11px", paddingLeft: "11px", paddingRight: "11px" }} src={selectedAboutsObj?.photos[0].url} alt="aktualnosc" />}
            {selectedAboutsObj?.pictures?.length > 1 &&
              <ImageGallery
                items={photosUrls}
                showFullscreenButton={false}
                showBullets={true}
                showPlayButton={false}
              />
            }
            {selectedAboutsObj?.pictures?.length === 0 && null}
          </div>
        </div>
      }
      {isTabletOrMobile &&
        <div>
          <div style={{ paddingLeft: "20px" }}>
            <Dropdown
              filter
              value={selectedValue}
              options={items}
              onChange={(e) => {
                setSelectedValue(e.value)
                getAboutsObj(e.value)
              }}

            />
            <h1 style={{ marginBottom: "10px" }}>{selectedAboutsObj?.title}</h1>
            {ReactHtmlParser(selectedAboutsObj?.content.html)}
            {selectedAboutsObj?.pictures?.length === 1 && <img style={{ width: "600px", height: "600px", marginBottom: "11px", paddingLeft: "11px", paddingRight: "11px" }} src={selectedAboutsObj?.photos[0].url} alt="aktualnosc" />}
            {selectedAboutsObj?.pictures?.length > 1 &&
              <ImageGallery
                items={photosUrls}
                showFullscreenButton={false}
                showBullets={true}
                showPlayButton={false}
              />
            }
            {selectedAboutsObj?.pictures?.length === 0 && null}
          </div>
        </div>
      }
    </>
  )
}

export default ParafiaItemsList;