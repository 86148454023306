import React from 'react';
import ReactPannellum from "react-pannellum";
import { useMediaQuery } from 'react-responsive';
import ComparePannellumSlider from './ComparePannellumSlider';
import { useQuery } from 'urql';
import ReactGA from "react-ga";

const Home = () => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });

    const config = {
        autoLoad: true,
        compass: true
    }

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    const MainAssetsQuery = `
    query{
        assets(where: {
            OR: [
            { fileName_contains: "gorny360-before" },
            { fileName_contains: "gorny360-after" },
            { fileName_contains: "dolny360" }
            ]
        }) {
            url
            fileName
        }
    }
    `;

    const [result] = useQuery({
        query: MainAssetsQuery
    });

    const { data, fetching, err } = result;

    if (fetching) return <p>Pobieram informacje z wpisu...</p>
    if (err) return <p>O nie... {err.message}</p>
    const images = data.assets;

    const getImage = (name) => {
        return images.filter(image => image.fileName.includes(name))[0]?.url
    }

    const image1 = getImage("gorny360-before");
    const image2 = getImage("gorny360-after");

    return (
        <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "20px" }}>
            <h3 style={{ display: "flex", justifyContent: "center" }}>Porównaj kościół górny przed i po remoncie</h3>
            <p style={{ display: "flex", justifyContent: "center" }}>Użyj suwaka, który jest na środku do przesuwania obrazów. Zarówno zdjęcie przed i po remoncie jest panoramą i można je obracać</p>
            <ComparePannellumSlider image1={image1} image2={image2} />
            <div style={{ dispaly: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column", marginTop: "10px", marginBottom: "10px" }} >
                <h3 style={{ display: "flex", justifyContent: "center" }}>Kościół dolny</h3>
                {
                    isDesktopOrLaptop &&
                    <ReactPannellum
                        id="3"
                        sceneId="thirdScene"
                        imageSource={images[2].url}
                        config={config}
                        style={{ width: "100%", height: "600px", borderRadius: "20px" }}
                    />
                }
                {
                    isTabletOrMobile &&
                    <ReactPannellum
                        id="3"
                        sceneId="thirdScene"
                        imageSource={images[2].url}
                        config={config}
                        style={{ paddingLeft: "20px", width: "100%", height: "300px", borderRadius: "20px" }}
                    />
                }
            </div>
        </div>
    )
}

export default Home;