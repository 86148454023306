import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import { SidebarS, SidebarList } from './Styles';
import { Timeline }from 'react-twitter-widgets';
import SideBarItem from './SideBarItem';

const SideBar = () => {

    return(
        <Container>
            <Row>
                <Col sx="12">
                    <SidebarS>
                        <SidebarList>            
                            <Container style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                    <h4 style={{fontWeight:"bold", color:"#a38a17"}}>Msze Święte</h4>
                                    <span>W dni powszednie:<br/></span><span><b>07:00 i 18:00 </b></span>
                                    <span style={{alignItems:"center"}}>W niedzielę:<br/></span><span style={{alignItems:"center"}}><b>07:00, 08:30, 10:00, 12:00 i 18:00</b></span>
                            </Container>      
                            <Container style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                    <h4 style={{fontWeight:"bold", color:"#a38a17"}}>Spowiedź</h4>                                    
                                    <span style={{fontWeight:"bold"}}>Przed każdą Mszą Świętą</span> 
                                    <span style={{}}>oraz</span>
                                    <span style={{fontWeight:"bold"}}> w Pierwszy Piątek Miesiąca</span>                                   
                            </Container>  
                            <Container style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                    <h4 style={{fontWeight:"bold", color:"#a38a17"}}>Kancelaria Parafialna</h4>
                                    <span>Kancelaria czynna w dniach i godzinach: </span>
                                    <span><b>Wtorek</b>: 16:30 do 17:30</span>
                                    <span><b>Środa</b>: 16:30 do 17:30</span>
                                    <span><b>Czwartek</b>: 08:45 do 09:30</span>
                                    <span><b>Piątek</b>: 16:30 do 17:30</span>
                                    <span><b>Sobota</b>: 08:45 do 09:30</span>
                                    <span><b>W Pierwszy Piątek Miesiąca</b></span>
                                    <span><b>kancelaria nieczynna</b></span>
                            </Container>                           
                            <div className="border-top my-2"></div>
                            <div>
                                <SideBarItem iframe='<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FParafiaMrozy%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="100%" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>' />
                            </div>
                            <div className='border-top my-2'></div>
                                <SideBarItem iframe='<iframe src="https://opoka.org.pl/liturgia_iframe" scrolling="yes" style="height: 500px; width: 100%" height="auto" frameborder="0"></iframe >'
                                             title="Liturgia dnia" />
                            <div className="border-top my-2"></div>
                            <div>
                                <SideBarItem  iframe='<iframe src="https://open.spotify.com/embed-podcast/show/2F5tOicGHhqpSH9NuKTqLz" width="100%" height="250" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>'
                                                title="Modlitwa w Drodze" />
                                
                            </div>   
                            <div className="border-top my-2"></div>
                            <div>
                                <h3 style={{display:'flex', justifyContent:'center'}}>Tweety Papieża</h3>
                                <Timeline 
                                    dataSource={{
                                        sourceType: 'profile',
                                        screenName: 'Pontifex_pl'
                                    }}    
                                    options={{
                                        width: '100%',
                                        height: '200',
                                        theme: 'dark',
                                        chrome: 'noheader, nofooter',
                                        lang: 'pl'
                                    }}  
                                    renderError={err => 
                                        "Wystąpił problem z ładowaniem tweetów papieża"
                                    }   
                                />                   
                            </div>
                            <div className="border-top my-2"></div>
                        </SidebarList>
                    </SidebarS>
                </Col>
            </Row>
        </Container>
    );
}

export default SideBar;