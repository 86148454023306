import React, { useState, useEffect } from 'react';
import { gql, useQuery } from "urql";
import { ListBox } from 'primereact/listbox';
import { useMediaQuery } from 'react-responsive';
import Gallery from './Gallery';
import styles from "./Gallery.module.css";
import { Dropdown } from 'primereact/dropdown';
import ReactGA from "react-ga";


const GalleriesQuery = gql`
    {
        gallery(orderBy: dodano_DESC){
        title
        photos{
            url
          }
        thumbnails {
            url
          }
        slug
        }
    } 
`;

const Galleries = () => {
    ReactGA.send({ hitType: "pageview", page: "/galerie", title: "Galerie" });

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedPictureObject, setSelectedPictureObject] = useState();
    const [items, setItems] = useState([]);

    const [result] = useQuery({
        query: GalleriesQuery
    });

    const { data, fetching, err } = result;

    const getPictureObj = (id) => {
        setSelectedPictureObject(data?.gallery[id])
    }

    useEffect(() => {
        const getPictures = async () => {
            setItems(data?.gallery?.map((obj, i) => ({ label: obj.title, value: i })))
        }

        getPictures();
        setSelectedPictureObject(data?.gallery[selectedValue])
    }, [data, selectedValue])

    if (fetching) return <p>Pobieram aktualności...</p>;
    if (err) return <p>O nie... {err.message}</p>;

    return (
        <>
            {isDesktopOrLaptop &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px" }}>
                    <div style={{ marginTop: "10px", marginLeft: "30px", marginRight: "30px" }}>
                        <div style={{ display: "flex", alignContent: "space-between" }}>
                            <Gallery style={{ width: "80%" }} gallery={selectedPictureObject} />
                            <div className={styles.card + " flex justify-content-end"}>
                                <ListBox
                                    filter
                                    value={selectedValue}
                                    onChange={(e) => {
                                        setSelectedValue(e.value)
                                        getPictureObj(e.value)
                                    }}
                                    options={items}
                                    virtualScrollerOptions={{ itemSize: 40 }}
                                    listStyle={{ height: "550px" }}
                                    style={{ marginLeft: "30px" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isTabletOrMobile &&
                <div styles={{ width: "470px" }}>
                    <div >
                        <div>
                            <Dropdown
                                filter
                                value={selectedValue}
                                onChange={(e) => {
                                    setSelectedValue(e.value)
                                    getPictureObj(e.value)
                                }}
                                options={items}
                                style={{ marginLeft: "30px" }}
                            />
                        </div>
                    </div>
                    <Gallery style={{ width: "45%", paddingLeft: "20px" }} gallery={selectedPictureObject} />
                </div>
            }
        </>
    )
}

export default Galleries;