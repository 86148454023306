import React from 'react';
import './Styles';
import { SideBarItemIframe, SideBarItemTitle } from './Styles';


const SideBarItem = (props) => {
    return(
        <>
            <SideBarItemTitle>{props.title}</SideBarItemTitle>
            <SideBarItemIframe dangerouslySetInnerHTML={{__html: props.iframe }}></SideBarItemIframe>
        </>
    );
}

export default SideBarItem;