import React, { useState, useEffect } from 'react';
import { gql, useQuery } from 'urql';
import { useMediaQuery } from 'react-responsive';
import { ListBox } from 'primereact/listbox';
import { Dropdown } from "primereact/dropdown";
import ReactHtmlParser from 'react-html-parser';
import styles from './News.module.css';
import ReactGA from "react-ga";

const OgloszeniaQuery = gql`
    {
        ogloszenias(orderBy: dodano_DESC){
            title
            dodano
            createdBy {
                name
            }
            content{
                html
            }
            slug
            photos{
                url
            }
        }
    }
`;

const NewsList = () => {
    ReactGA.send({ hitType: "pageview", page: "/ogloszenia", title: "Ogloszenia" });

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    const [result] = useQuery({
        query: OgloszeniaQuery
    });

    const { data, fetching, err } = result;

    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedOgloszeniaObj, setSelectedOgloszeniaObj] = useState();
    const [items, setItems] = useState([]);

    const getOgloszeniasObj = (id) => {
        setSelectedOgloszeniaObj(data?.ogloszenias[id])
    }

    useEffect(() => {
        const getOgloszenias = async () => {
            setItems(data?.ogloszenias?.map((obj, i) => ({
                label: obj.title.length > 30 ? obj.title.substring(0, 30) + ` ...` : obj.title,
                value: i
            })))
        }

        setSelectedOgloszeniaObj(data?.ogloszenias[selectedValue])
        getOgloszenias();
    }, [data, selectedValue]);

    if (fetching) return <p>Pobieram dane...</p>
    if (err) return <p>O nie {err.message}</p>

    return (
        <>
            {isDesktopOrLaptop &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px", flexDirection: "row" }}>
                    <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                        <div style={{ marginLeft: "30px", marginRight: "30px", width: "80%" }}>
                            {ReactHtmlParser(selectedOgloszeniaObj?.content.html)}
                        </div>
                        <div className={styles.card + " flex justify-content-end"}>
                            <ListBox
                                filter
                                value={selectedValue}
                                options={items}
                                onChange={(e) => {
                                    setSelectedValue(e.value)
                                    getOgloszeniasObj(e.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
            }
            {isTabletOrMobile &&
                <>
                    <div style={{ paddingLeft: "20px", }}>
                        <Dropdown
                            filter
                            value={selectedValue}
                            options={items}
                            onChange={(e) => {
                                setSelectedValue(e.value)
                                getOgloszeniasObj(e.value)
                            }}
                        />
                        <div style={{ width: "100%" }}>
                            {ReactHtmlParser(selectedOgloszeniaObj?.content.html)}
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default NewsList;