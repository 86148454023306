import React from 'react';
import { Row, Col} from "react-bootstrap";
import styles from './Content.module.css';
import PageContent from '../PageContent/PageContent'
import SideBar from '../SideBar/SideBar.js';
import BGImages from '../../../../../bg-images';
import { useMediaQuery } from 'react-responsive';

const Content = () => {

    const images = BGImages.map((item) => {return item.image});

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    return(
        <div className="mainContent" as={"main"}> 
            { isDesktopOrLaptop && 
            <Row className={styles.content}>
                <Col sx="1" lg="3">
                    <SideBar/>
                </Col>
                <Col sx="11" lg="9">
                    <PageContent/>
                </Col>
            </Row>}
            { isTabletOrMobile && 
            <Row className={styles.content}>
                <Col sx="1" lg="3"></Col>
                <Col sx="11" lg="9">
                    <PageContent style={{padding:"auto", display:"flex", justifyContent:"center", flexDirection:"column"}} />
                </Col>                
            </Row>}
            { isDesktopOrLaptop &&                 
                images.map((pict, index) =>
                    <img key={index} style={{height:"2200px", width:"100%"}} src={pict} alt="Background"/>
                )
            }
        </div>
    );
}


export default Content;