import React from 'react';
import AddressMap from './AddressMap';
import ReactGA from "react-ga";

const Contact = () => {
    ReactGA.send({ hitType: "pageview", page: "/kontakt", title: "Kontakt" });

    return (
        <>
            <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "20px" }}>
                <div style={{ marginTop: "10px", marginLeft: "30px", marginRight: "30px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <h3>Parafia Rzymskokatolicka pw. św. Teresy od Dzieciątka Jezus w Mrozach</h3>
                    <h5>ul. Św.Teresy 3, 05-320 Mrozy</h5>
                    <h5>tel. 25 757 41 93</h5>
                    <h5><a href="mailto:parafiamrozy@gmail.com">parafiamrozy@gmail.com</a></h5>
                </div>
                <AddressMap style={{ marginTop: "10px", marginLeft: "30px", marginRight: "30px", }} />
                <div className="border-top my-2"></div>
                <div style={{ marginLeft: "30px", marginRight: "30px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <h3>Jeśli chcesz nas wesprzeć:</h3>
                    <p>OFIARY NA RZECZ POTRZEB PARAFII MOŻNA WPŁACAĆ NA KONTO:</p>
                    <p>    PARAFIA ŚW. TERESY OD DZIECIĄTKA JEZUS W MROZACH</p>
                    <p>    Bank Spółdzielczy w Mrozach </p>
                    <p>    NR 58 9227 0004 0000 0808 2000 0010</p>
                    <p>    - darowizna na cele kultu religijnego</p>
                </div>
            </div>
        </>
    );
}

export default Contact;