import React, { useEffect, useState } from 'react'
import { useQuery } from 'urql';
import { useMediaQuery } from 'react-responsive';
import ReactHtmlParser from "react-html-parser";
import { ListBox } from 'primereact/listbox';
import { Dropdown } from "primereact/dropdown";
import ReactGA from "react-ga";

const SacramentsQuery = `
    {
    mainInfos(where: {category_in: "Sakrament"}) {
        title
        content {
            html
        }
        dodano
    }
    }
`;


const Sacraments = () => {
    ReactGA.send({ hitType: "pageview", page: "/sakramenty", title: "Sakramenty" });

    const [result] = useQuery({ query: SacramentsQuery });
    const { data, fetching, err } = result;

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedSacramentsObj, setSelectedSacramentsObj] = useState();
    const [items, setItems] = useState([]);

    const getAboutsObj = (id) => {
        setSelectedSacramentsObj(data?.mainInfos[id])
    }

    useEffect(() => {
        const getAbouts = async () => {
            setItems(data?.mainInfos?.map((obj, i) => ({
                label: obj.title.length > 30 ? obj.title.substring(0, 30) + ` ...` : obj.title,
                value: i
            })))
        }

        setSelectedSacramentsObj(data?.mainInfos[selectedValue])
        getAbouts();
    }, [data, selectedValue]);

    if (fetching) return <p>Pobieram informacje o parafii...</p>
    if (err) return <p>O nie... {err.Message}</p>

    return (
        <>
            {isDesktopOrLaptop &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px", }}>
                    {!selectedSacramentsObj ? (
                        <div style={{ paddingLeft: "20px" }}>
                            <h1 style={{ marginBottom: "10px", display: "flex", justifyContent: "center" }}>Niestety na razie nic tu nie ma :(</h1>
                        </div>
                    ) : (
                        <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>

                            <div style={{ width: "100%", marginLeft: "30px", marginRight: "30px", flexDirection: "column" }}>
                                <h1 style={{ marginBottom: "10px" }}>{selectedSacramentsObj?.title}</h1>
                                {ReactHtmlParser(selectedSacramentsObj?.content.html)}
                            </div>
                            <div style={{ width: "50%" }}>
                                <ListBox
                                    filter
                                    value={selectedValue}
                                    options={items}
                                    onChange={(e) => {
                                        setSelectedValue(e.value)
                                        getAboutsObj(e.value)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            }
            {isTabletOrMobile &&
                <div>
                    {!selectedSacramentsObj ? (
                        <div style={{ paddingLeft: "20px" }}>
                            <h1 style={{ marginBottom: "10px", display: "flex", justifyContent: "center" }}>Niestety na razie nic tu nie ma :(</h1>
                        </div>
                    ) : (
                        <div style={{ paddingLeft: "20px" }}>
                            <Dropdown
                                filter
                                value={selectedValue}
                                options={items}
                                onChange={(e) => {
                                    setSelectedValue(e.value)
                                    getAboutsObj(e.value)
                                }}

                            />
                            <h1 style={{ marginBottom: "10px" }}>{selectedSacramentsObj?.title}</h1>
                            {ReactHtmlParser(selectedSacramentsObj?.content.html)}
                        </div>
                    )}
                </div>
            }
        </>
    )
}

export default Sacraments;