import styled from 'styled-components';

export const SidebarS = styled.ul`
    height: auto;
    width: auto;
    background-color: white;
    position: absolute;
    margin-top:10px;
    border-radius: 10px;
`;

export const SidebarList = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
`;

export const SidebarElem = styled.li`
    margin-bottom: 20px;
    
    a{
        text-decoration: none;
        color: black;
    }
`;

export const SideBarItemTitle = styled.h3`
    display: flex;
    justify-content: center;
`;

export const SideBarItemIframe = styled.div`
    display: flex;
    justify-content: center;
    width:100%;
`;