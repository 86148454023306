import React, { useState, useRef, useEffect } from 'react';
import ReactPannellum from 'react-pannellum';
import Slider from 'react-slider';
import './ComparePannellumSlider.css';

const ComparePannellumSlider = ({ image1, image2 }) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const sliderRef = useRef(null);
  const containerRef = useRef(null);

  const config = {
    autoLoad: true,
    compass: true
  };

  const handleSliderChange = (value) => {
    setSliderPosition(value);
  };

  useEffect(() => {
    const handleDocumentMouseMove = (e) => {
      if (sliderRef.current && sliderRef.current.contains(e.target)) {
        e.stopPropagation();
      }
    };

    document.addEventListener('mousemove', handleDocumentMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleDocumentMouseMove);
    };
  }, []);

  const clipValue = `${sliderPosition}%`;

  return (
    <div ref={containerRef} style={{ position: 'relative', width: '100%', height: '500px' }}>
      {/* Pannellum Component for First Panorama */}
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <ReactPannellum
          id="1"
          sceneId="firstScene"
          imageSource={image1}
          config={config}
          style={{ width: "100%", height: "100%", borderRadius: '20px' }}
        />
      </div>

      {/* Pannellum Component for Second Panorama with Clip Path */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          clipPath: `polygon(0 0, ${clipValue} 0, ${clipValue} 100%, 0 100%)`,
          zIndex: 1
        }}
      >
        <ReactPannellum
          id="2"
          sceneId="secondScene"
          imageSource={image2}
          config={config}
          style={{ width: "100%", height: "100%", borderRadius: '20px' }}
        />
      </div>

      {/* Slider for Adjusting the Comparison */}
      <div
        ref={sliderRef}
        style={{
          position: 'absolute',
          top: '50%',
          left: 0,
          width: '100%',
          transform: 'translateY(-50%)',
          zIndex: 3,
        }}
      >
        <Slider
          value={sliderPosition}
          onChange={handleSliderChange}
          className="slider"
          thumbClassName="slider-thumb"
          trackClassName="slider-track"
          min={0}
          max={100}
          ariaLabel={['Left handle']}
          renderThumb={(props, state) => (
            <div
              {...props}
              className="slider-thumb"
              style={{
                position: 'absolute',
                left: `${sliderPosition}%`,
                transform: 'translateX(-50%)',
                zIndex: 4,
                cursor: 'pointer'
              }}
            >
              <div className="slider-thumb-inner">
                <span>&#9664;</span>
                <span>&#9654;</span>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default ComparePannellumSlider;
