import React, { useState, useEffect } from 'react';
import { useQuery } from 'urql';
import ImageGallery from 'react-image-gallery';
import ReactHtmlParser from 'react-html-parser';
import "react-image-gallery/styles/css/image-gallery.css";
import { useParams } from 'react-router-dom';
import ReactGA from "react-ga";

const AboutBySlug = `
query($slug: String!){
    oParafiis(where:{slug: $slug}){
      title
      slug
      content{
        html
      }
      pictures {
        url
      }
      thumbnails{
        url
      }
      dodano
      createdBy{
        name
      }
    }
  } 
`;

const Parafia = (props) => {
  ReactGA.send({ hitType: "pageview", page: "/oParafii", title: "O Parafii" });

  const param = useParams()
  const slug = param.slug

  const [result] = useQuery({
    query: AboutBySlug,
    variables: {
      slug
    }
  })

  const { data, fetching, err } = result;

  const [photosUrls, setPhotosUrl] = useState([]);


  useEffect(() => {
    const array = data?.oParafiis[0].pictures?.map((obj, index) => {
      return {
        id: index,
        original: obj.url,
        thumbnail: data?.oParafiis[0].thumbnails[index]?.url
      }
    })
    setPhotosUrl(array)
  }, [data])

  if (fetching) return <p>Pobieram dane dla wpisu</p>
  if (err) return <p>O nie... {err.message}</p>

  return (
    <>
      <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px" }}>
        <div style={{ marginTop: "10px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          <h1 style={{ marginBottom: "10px" }}>{data?.oParafiis[0]?.title}</h1>
          <div style={{ marginLeft: "30px", marginRight: "30px" }}>
            {ReactHtmlParser(data?.oParafiis[0]?.content.html)}
          </div>
          {photosUrls?.length > 0 &&
            <ImageGallery
              style={{ marginBottom: "10px" }}
              items={photosUrls}
              slideOnThumbnailOver={true}
            />
          }
        </div>
      </div>
    </>
  );
}

export default Parafia;