import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './Components/Views/Parts/Header/Header/Header';
import Footer from './Components/Views/Parts/Footer/Footer/Footer';
import Content from './Components/Views/Parts/Content/Content/Content';
import { useMediaQuery } from 'react-responsive';
// import env from 'react-dotenv';
import ReactGA from "react-ga";

import './App.css';

function App() {

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const trackingCode = process.env.REACT_APP_TRACKING_CODE;

  ReactGA.initialize(trackingCode);

  return (
    <div className="App">
      <Router>
        <Route path={"/"} render={() => <Header />} />
        <Route path={"/"} render={() => <Content />} />
      </Router>
      <div>
        <Router>
          {isDesktopOrLaptop && <Route path={"/"} render={() => <Footer />} />}
        </Router>
      </div>
    </div>
  );
}

export default App;
