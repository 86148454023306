const LOGO = [
    {
        id:1,
        image: '/Assets/logos/1ikony.png'
    },
    {
        id:2,
        image: '/Assets/logos/2ikony.png'
    }
]

export default LOGO;