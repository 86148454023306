import { React, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { Document, Page, pdfjs } from 'react-pdf';
import './Intention.css';
import { useMediaQuery } from 'react-responsive';
import ReactGA from "react-ga";

const IntentionBySlug = `
    query($slug: String!){
        intention(where:{slug:$slug}){
            title
            slug
            pdf{
              url
            }
            createdBy {
              name
            }
            dodano
        }
    }
`;

const Intention = (props) => {
    ReactGA.send({ hitType: "pageview", page: "/intencje", title: "Intencje" });

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const param = useParams()
    const slug = param.slug
    const [result] = useQuery({
        query: IntentionBySlug,
        variables: {
            slug
        }
    })

    const { data, fetching, err } = result;

    if (fetching) return <p>Pobieram informacje z wpisu...</p>
    if (err) return <p>O nie... {err.message}</p>

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const previousPage = () => setPageNumber(pageNumber - 1);
    const nextPage = () => setPageNumber(pageNumber + 1);

    return (
        <>
            {isDesktopOrLaptop &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px" }}>
                    <div key={slug} style={{ marginTop: "10px", width: "100%", display: "flex", justifyContent: "center" }}>
                        {data.intention?.map((props) => (
                            <>
                                <div key={props.slug}>
                                    <h1>{props.title}</h1>
                                    <div style={{ width: "100%" }}>
                                        <Document
                                            file={props.pdf.url}
                                            options={{ workerSrc: "/pdf.worker.js" }}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            <Page pageNumber={pageNumber}
                                                width="1000"
                                            />
                                        </Document>
                                    </div>
                                    <div>
                                        <p>
                                            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                        </p>
                                        <button className="button" type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                            Poprzednia
                                        </button>
                                        <button
                                            className="button"
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            Następna
                                        </button>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            }
            {isTabletOrMobile &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px", marginLeft: "10px" }}>
                    <div key={props.slug} style={{ paddingLeft: "20px", marginTop: "10px", width: "100%", display: "flex", justifyContent: "center" }}>
                        {data.intention?.map((props) => (
                            <>
                                <div key={props.slug}>
                                    <h1>{props.title}</h1>
                                    <div style={{ width: "100%" }}>
                                        <Document
                                            file={props.pdf.url}
                                            options={{ workerSrc: "/pdf.worker.js" }}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            <Page pageNumber={pageNumber}
                                                width="400"
                                            />
                                        </Document>
                                    </div>
                                    <div style={{ justifyContent: "center" }}>
                                        <p>
                                            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                        </p>
                                        <button className="button" type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                            Poprzednia
                                        </button>
                                        <button
                                            className="button"
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            Następna
                                        </button>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            }
        </>
    );
}

export default Intention;