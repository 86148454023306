import React, { useState, useEffect } from 'react';
import { Nav, Navbar, NavbarBrand, NavDropdown, NavItem, NavLink } from "react-bootstrap";
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import { Link } from "react-router-dom";
import LOGO from '../../../../../logos';
import { useMediaQuery } from 'react-responsive';

const AppNavbar = () => {

    const [index, setIndex] = useState(0);
    const images = LOGO.map((item) => { return item.image });

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    useEffect(() => {
        const timer = setInterval(() => {
            if (index === images.length - 1) {
                setIndex(0);
            } else {
                setIndex(index + 1);
            }
        }, 10000)
        return () => clearInterval(timer);
    },);


    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                {isDesktopOrLaptop &&
                    <NavbarBrand className='col-6 col-md-3' as={Link} to="/">
                        <img style={{ height: "100px", width: "350px" }} src={images[index]} alt="logo" />
                    </NavbarBrand>
                }
                {isTabletOrMobile &&
                    <NavbarBrand className='col-6 col-md-3' as={Link} to="/">
                        <img style={{ height: "100px", width: "200px" }} src={images[index]} alt="logo" />
                    </NavbarBrand>
                }
                <NavbarToggle aria-controls="responsive-navbar-nav" />
                <NavbarCollapse id="responsive-navbar-nav">
                    <Nav className="col-12 col-sm-8 col-md-10" variant="pils">
                        <NavDropdown title="Aktualności" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/aktualnosci" eventKey="Aktualności" title="Aktualności">
                                Aktualności
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/aktualnosc/informacje-dla-bierzmowanych" eventKey="Bierzmowani" title="Bierzmowani" style={{ color: "red" }}>
                                Bierzmowanie
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavItem href="/ogloszenia">
                            <NavLink as={Link} to="/ogloszenia" eventKey="Ogloszenia" title="Ogłoszenia">
                                Ogłoszenia
                            </NavLink>
                        </NavItem>
                        <NavItem href="/intencje">
                            <NavLink as={Link} to="/intencje" eventKey="Intencje" title="Intencje">
                                Intencje
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink as={Link} to={{ pathname: "https://www.facebook.com/ParafiaMrozy" }} target="_blank" eventKey="Facebook" title="Facebook Parafii">
                                {"Facebook"}
                            </NavLink>
                        </NavItem>
                        <NavDropdown title="Parafia" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/parafia" eventKey="Parafia" title="Parafia">
                                Parafia
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/sakramenty" eventKey="Sakramenty" title="Sakramenty">
                                Sakramenty
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={{ pathname: "http://www.polski-cmentarz.pl/mrozy/grobonet/start.php" }} target="_blank" eventKey="Cmentarz" title="Cmentarz">
                                Cmentarz
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={{ pathname: "https://www.karmel.pl/swieta-teresa-od-dzieciatka-jezus-teresa-martin-1873-1897/?fbclid=IwAR0xM5HvpWfPJfL2FUT0oWxHJfPwp6xvS5mX7YxOG157_UKiUMk4dc_D7z0" }} target="_blank" eventKey="Patron" title="Patronka">
                                Patronka
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavItem href="/live">
                            <NavLink as={Link} to="/live" eventKey="live" >
                                Transmisje Mszy Świętych
                            </NavLink>
                        </NavItem>
                        <NavItem href="/galerie">
                            <NavLink as={Link} to="/galerie" eventKey="Galeria" title="Galeria">
                                Galerie
                            </NavLink>
                        </NavItem>
                        <NavItem href="/kontakt">
                            <NavLink as={Link} to="/kontakt" eventKey="Kontakt" title="Kontakt">
                                Kontakt
                            </NavLink>
                        </NavItem>
                    </Nav>
                </NavbarCollapse>
            </Navbar>
        </>
    );
}

export default AppNavbar;