import {React} from 'react';
import {Container} from "react-bootstrap";
import Navbar from '../NavBar/NavBar';
import './Header.css'

import 'react-slideshow-image/dist/styles.css'

const Header = () => {

    return(
        <Container className="HeaderContainer" fluid={true} as={"header"}>
            <Navbar/>
		</Container>
    );
}

export default Header;