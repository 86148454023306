import React from 'react';
import { useMediaQuery } from 'react-responsive';

const AddressMap=()=>{

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    return (
        <div className="google-map-code">
          {isDesktopOrLaptop && 
            <iframe title="ParafiaLocation" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2447.2182076160284!2d21.79991185365588!3d52.16672600576672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x53a3ebf836e6056!2sParafia%20rzymskokatolicka%20%C5%9Bw.%20Teresy%20od%20Dzieci%C4%85tka%20Jezus!5e0!3m2!1spl!2spl!4v1639934433680!5m2!1spl!2spl" width="100%" height="600" style={{border:0, borderRadius:"20px", marginTop:"10px"}} allowFullScreen="" loading="lazy"></iframe>
          }
          {isTabletOrMobile && 
            <iframe title="ParafiaLocation" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2447.2182076160284!2d21.79991185365588!3d52.16672600576672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x53a3ebf836e6056!2sParafia%20rzymskokatolicka%20%C5%9Bw.%20Teresy%20od%20Dzieci%C4%85tka%20Jezus!5e0!3m2!1spl!2spl!4v1639934433680!5m2!1spl!2spl" width="100%" height="600" style={{paddingLeft:"20px", border:0, borderRadius:"20px", marginTop:"10px"}} allowFullScreen="" loading="lazy"></iframe>
          }
        </div>
    );
 }
 
 export default AddressMap;