import { React, useState, useEffect } from 'react';
import { gql, useQuery } from 'urql';
import { useMediaQuery } from 'react-responsive';
import { Dropdown } from "primereact/dropdown";
import { ListBox } from 'primereact/listbox';
import { Document, Page, pdfjs } from 'react-pdf';
import ReactGA from "react-ga";

const IntentionsQuery = gql`
{
    intention(orderBy: dodano_DESC){
      title
      slug
      pdf{
        url
      }
    }
  }
`;



const Intentions = () => {
    ReactGA.send({ hitType: "pageview", page: "/intencje-od", title: "Intentcje Od" });

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [result] = useQuery({
        query: IntentionsQuery
    });

    const { data, fetching, err } = result;

    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedIntentionsObj, setSelectedIntentionsObj] = useState();
    const [items, setItems] = useState([]);

    const getOgloszeniasObj = (id) => {
        setSelectedIntentionsObj(data?.intention[id])
    }

    useEffect(() => {
        const getIntentions = async () => {
            setItems(data?.intention?.map((obj, i) => ({
                label: obj.title.length > 30 ? obj.title.substring(0, 30) + ` ...` : obj.title,
                value: i
            })))
        }

        setSelectedIntentionsObj(data?.intention[selectedValue])
        getIntentions();
    }, [data, selectedValue]);

    if (fetching) return <p>Pobieram dane...</p>
    if (err) return <p>O nie {err.message}</p>

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const previousPage = () => setPageNumber(pageNumber - 1);
    const nextPage = () => setPageNumber(pageNumber + 1);

    return (
        <>
            {isDesktopOrLaptop &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px", flexDirection: "row" }}>
                    <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                        <div style={{ marginLeft: "30px", marginRight: "30px", width: "80%" }}>
                            <>
                                <div key={selectedIntentionsObj?.slug}>
                                    <h1>{selectedIntentionsObj?.title}</h1>
                                    <div style={{ width: "100%" }}>
                                        <Document
                                            file={selectedIntentionsObj?.pdf.url}
                                            options={{ workerSrc: "/pdf.worker.js" }}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            <Page pageNumber={pageNumber}
                                                width="1000"
                                            />
                                        </Document>
                                    </div>
                                    <div>
                                        <p>
                                            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                        </p>
                                        <button className="button" type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                            Poprzednia
                                        </button>
                                        <button
                                            className="button"
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            Następna
                                        </button>
                                    </div>
                                </div>
                            </>
                        </div>
                        <div className={"card flex "}>
                            <ListBox
                                filter
                                value={selectedValue}
                                options={items}
                                onChange={(e) => {
                                    setSelectedValue(e.value)
                                    getOgloszeniasObj(e.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
            }
            {isTabletOrMobile &&
                <>
                    <div style={{ paddingLeft: "20px", width: "100%" }}>
                        <Dropdown
                            filter
                            value={selectedValue}
                            options={items}
                            onChange={(e) => {
                                setSelectedValue(e.value)
                                getOgloszeniasObj(e.value)
                            }}
                        />
                        <>
                            <div key={selectedIntentionsObj?.slug}>
                                <h1>{selectedIntentionsObj?.title}</h1>
                                <div style={{ width: "100%" }}>
                                    <Document
                                        file={selectedIntentionsObj?.pdf.url}
                                        options={{ workerSrc: "/pdf.worker.js" }}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber}
                                            width="300"
                                        />
                                    </Document>
                                </div>
                                <div style={{ justifyContent: "center" }}>
                                    <p>
                                        Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                    </p>
                                    <button className="button" type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                        Poprzednia
                                    </button>
                                    <button
                                        className="button"
                                        type="button"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}
                                    >
                                        Następna
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </>
            }
        </>
    )
}

export default Intentions;